import React, { Component } from 'react';
import './App.css';
import '../node_modules/react-vis/dist/style.css';
import 'bootstrap/dist/css/bootstrap.css';

import {
  FlexibleXYPlot,
  LineSeries,
  XAxis,
  YAxis,
  Crosshair,
} from 'react-vis';
import { differenceInSeconds, format, parse, subDays, subHours } from "date-fns";
import {
  Alert,
  Button,
  Col,
  Container,
  Nav,
  Row,
  Tab,
  Tabs
} from "react-bootstrap";
import { parseUrl } from "query-string";

import DateSpanPicker from "./DateSpanPicker.js";
import TimeSpanPicker from "./TimeSpanPicker.js";
import VerticalBarWithErrorsSeries from "./VerticalBarWithErrorsSeries.js";


const READINGS_PATH = "/readings"
const DAILY_SUMMARIES_PATH = "/summaries/daily"

class App extends Component {
  constructor(props) {
    super(props);
    const toTimestamp = new Date();
    const fromTimestamp = subHours(toTimestamp, 12);
    const toDate = new Date();
    const fromDate = subDays(toDate, 14);
    this.state = {
      error: null,
      isLoaded: false,
      readings: [],
      dailySummaries: [],
      readingCrossHairValues: {
        pressure: false,
        temperature: false,
        humidity: false,
      },
      dailySummaryCrossHairValues: {
        pressure: false,
        temperature: false,
        humidity: false,
      },
      latestData: {
        timestamp: null,
        pressure: null,
        temperature: null,
        humidity: null,
      },
      showMenu: true,
      fromTimestamp: fromTimestamp,
      toTimestamp: toTimestamp,
      fromDate: fromDate,
      toDate: toDate,
      errors: [],
    };
    this.locale = {
      "format": "d MMM yyyy @ HH:mm:ss",
      "sundayFirst": false
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/readings/latest`
    ).then(res => res.json()).then(
      (result) => {
        const toTimestamp = new Date(result.timestamp);
        const fromTimestamp = subHours(toTimestamp, 12);
        const toDate = new Date(toTimestamp);
        const fromDate = subDays(toDate, 14);

        this.setTimestamps(fromTimestamp, toTimestamp);
        this.setDates(fromDate, toDate);
      }
    );
  }

  componentDidMount() {
    this.updateTimer = setInterval(() => this.fetchLatest(), 15000);
    this.fetchLatest();
  }

  componentWillUnmount() {
    clearInterval(this.updateTimer);
  }

  addError(error) {
    console.log(error);
    var errors = this.state.errors;
    errors.push(error);
    this.setState({
      errors: errors,
    })
  }

  removeError(index) {
    var errors = this.state.errors;
    errors.splice(index, 1);
    this.setState({
      errors: errors,
    });
  }

  setTimestamps(fromTimestamp, toTimestamp) {
    this.setState({
      fromTimestamp: fromTimestamp,
      toTimestamp: toTimestamp,
    });
    try {
      this.fetchReadings(fromTimestamp, toTimestamp);
    } catch (e) {
      this.addError(`Failed to fetch data: ${e}`);
    }
  }

  setDates(fromDate, toDate) {
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
    });
    try {
      this.fetchDailySummaries(fromDate, toDate);
    } catch (e) {
      this.addError(`Failed to fetch data: ${e}`);
    }
  }

  fetchDailySummaries(from, to) {
    const fromDate = encodeURIComponent(format(from, "yyyy-MM-dd"))
    const toDate = encodeURIComponent(format(to, "yyyy-MM-dd"))

    const url =
      `${process.env.REACT_APP_API_URL}/summaries/daily` +
      `?from_date=${fromDate}&to_date=${toDate}`;

    fetch(url).then(res => res.json())
      .then(
        (result) => {
          if (result.message) {
            this.addError(`Failed to fetch data: ${result.message}`);
          } else {
            this.setState({
              isLoaded: true,
              dailySummaries: result,
            })
          }
        },
        (error) => {
          this.addError(`Failed to fetch data: ${error}`);
          this.setState({
            isLoaded: true,
            error,
          });
        }
      )
  }

  fetchReadings(from, to) {
    const fromTimestamp = encodeURIComponent(from.toISOString());
    const toTimestamp = encodeURIComponent(to.toISOString());

    const interval = function () {
      const elapsedSeconds = differenceInSeconds(to, from);
      if (elapsedSeconds >= 720 * 60 * 60) {
        throw new Error("Specified timespan is too large.");
      }
      const intervalSeconds = elapsedSeconds / 720;
      var intervalMinutes = Math.ceil(intervalSeconds / 60);
      while (60 % intervalMinutes > 0) {
        intervalMinutes += 1;
      }
      return intervalMinutes;
    }();
    const intervalSuffix = interval ? `&interval=${interval}` : "";

    const url =
      `${process.env.REACT_APP_API_URL}/readings` +
      `?from_timestamp=${fromTimestamp}` +
      `&to_timestamp=${toTimestamp}` +
      `${intervalSuffix}`;

    fetch(url).then(res => res.json())
      .then(
        (result) => {
          if (result.message) {
            this.addError(`Failed to fetch data: ${result.message}`);
          } else {
            this.setState({
              isLoaded: true,
              readings: result,
            });
          }
        },
        (error) => {
          this.addError(`Failed to fetch data: ${error}`);
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  fetchLatest() {
    fetch(
      `${process.env.REACT_APP_API_URL}/readings/latest`
    ).then(res => res.json()).then(
      (result) => {
        this.setState({
          latestData: {
            timestamp: new Date(result.timestamp),
            pressure: result.sea_level_air_pressure,
            temperature: result.temperature,
            humidity: result.relative_humidity,
          },
        })
      }
    )
  }

  extractReadings(readings, dataExtractor) {
    return readings.map(
      (item) => {
        return {
          x: new Date(item.timestamp),
          y: dataExtractor(item),
        }
      }
    )
  }

  extractSummaries(summaries, dataExtractor) {
    return summaries.map(
      (item) => {
        return {
          x: parse(item.date, "yyyy-MM-dd", new Date()),
          yMin: dataExtractor(item).min,
          yMean: Math.round(dataExtractor(item).mean * 100) / 100,
          yMax: dataExtractor(item).max,
        }
      }
    )
  }

  downloadReadings() {
    const { readings } = this.state;
    const csv =
      "timestamp,air_pressure,sea_level_air_pressure,temperature,relative_humidity\n" +
      readings.map(
        (r) => (
          `${r.timestamp},` +
          `${r.air_pressure},` +
          `${r.sea_level_air_pressure},` +
          `${r.temperature},` +
          `${r.relative_humidity}\n`
        )
      ).join("");
    const data = new Blob([csv], { type: "text/csv" });
    const dataURL = window.URL.createObjectURL(data);
    var tmpLink = document.createElement('a');
    tmpLink.href = dataURL;
    tmpLink.setAttribute("download", "readings.csv");
    tmpLink.click();
  }

  downloadDailySummaries() {
    const { dailySummaries } = this.state;
    const csv =
      "date," +
      "air_pressure_min," +
      "air_pressure_mean," +
      "air_pressure_max," +
      "sea_level_air_pressure_min," +
      "sea_level_air_pressure_mean," +
      "sea_level_air_pressure_max," +
      "temperature_min," +
      "temperature_mean," +
      "temperature_max," +
      "relative_humidity_min," +
      "relative_humidity_mean," +
      "relative_humidity_max\n" +
      dailySummaries.map(
        (r) => (
          `${r.date},` +
          `${r.air_pressure.min},` +
          `${Math.round(r.air_pressure.mean * 100) / 100},` +
          `${r.air_pressure.max},` +
          `${r.sea_level_air_pressure.min},` +
          `${Math.round(r.sea_level_air_pressure.mean * 100) / 100},` +
          `${r.sea_level_air_pressure.max},` +
          `${r.temperature.min},` +
          `${Math.round(r.temperature.mean * 100) / 100},` +
          `${r.temperature.max},` +
          `${r.relative_humidity.min},` +
          `${Math.round(r.relative_humidity.mean * 100) / 100},` +
          `${r.relative_humidity.max}\n`
        )
      ).join("");
    const data = new Blob([csv], { type: "text/csv" });
    const dataURL = window.URL.createObjectURL(data);
    var tmpLink = document.createElement('a');
    tmpLink.href = dataURL;
    tmpLink.setAttribute("download", "daily_summaries.csv");
    tmpLink.click();
  }

  renderSummaries() {
    const { dailySummaries, dailySummaryCrossHairValues } = this.state;
    const parameterData = [
      {
        yAxisLabel: "Pressure / hPa",
        dataExtractor: (item) => item.sea_level_air_pressure,
        crossHairValue: dailySummaryCrossHairValues.pressure,
        crossHairValueAttr: "pressure",
      },
      {
        yAxisLabel: "Temperature / °C",
        dataExtractor: (item) => item.temperature,
        crossHairValue: dailySummaryCrossHairValues.temperature,
        crossHairValueAttr: "temperature",
      },
      {
        yAxisLabel: "Relative Humidity / %",
        dataExtractor: (item) => item.relative_humidity,
        crossHairValue: dailySummaryCrossHairValues.humidity,
        crossHairValueAttr: "humidity",
      },
    ];

    const getFormattedX = (v) => {
      if (v && v.x) {
        return format(v.x, "d MMM yyyy");
      }
      return "";
    }

    const renderedPlots = parameterData.map((pd) => {
      const summaries = this.extractSummaries(dailySummaries, pd.dataExtractor);
      const xMin = Math.min(...summaries.map(e => e.x));
      const xMax = Math.max(...summaries.map(e => e.x));
      const yMin = Math.min(...summaries.map((e) => e.yMin));
      const yMax = Math.max(...summaries.map((e) => e.yMax));
      const yPad = (yMax - yMin) * 0.1;

      function makeState(v) {
        var ret = {
          ...dailySummaryCrossHairValues,
        };
        ret[pd.crossHairValueAttr] = v;
        return ret;
      }

      return (
        <Row>
          <Col className="">
            <FlexibleXYPlot
              stroke="#343a40"
              fill="#007bff"
              xType="time"
              height={400}
              xDomain={summaries.length > 1 ? [xMin, xMax] : [0, 0]}
              yDomain={summaries.length > 1 ? [yMin - yPad, yMax + yPad] : [0, 0]}
              onMouseLeave={() => this.setState({ dailySummaryCrossHairValues: makeState(false) })}
            >
              <Crosshair
                style={{
                  box: { background: "#000000", strokeWidth: 2 },
                  line: { opacity: 0 },
                }}
                values={pd.crossHairValue ? [pd.crossHairValue] : []}
              >
                <div className="cross-hair">
                  <p>{getFormattedX(pd.crossHairValue)}</p>
                  <p>Min: {pd.crossHairValue.yMin}</p>
                  <p>Mean: {pd.crossHairValue.yMean}</p>
                  <p>Max: {pd.crossHairValue.yMax}</p>
                </div>
              </Crosshair>
              <XAxis />
              <YAxis title={pd.yAxisLabel} />
              <VerticalBarWithErrorsSeries
                data={summaries}
                onNearestX={(d) => this.setState({
                  dailySummaryCrossHairValues: makeState(d)
                })}
              />
            </FlexibleXYPlot>
          </Col>
        </Row>
      );
    });

    return renderedPlots;
  }

  renderReadings() {
    const { readings, readingCrossHairValues } = this.state;

    const parameterData = [
      {
        yAxisLabel: "Pressure / hPa",
        dataExtractor: (item) => item.sea_level_air_pressure,
        crossHairValue: readingCrossHairValues.pressure,
        crossHairValueAttr: "pressure",
      },
      {
        yAxisLabel: "Temperature / °C",
        dataExtractor: (item) => item.temperature,
        crossHairValue: readingCrossHairValues.temperature,
        crossHairValueAttr: "temperature",
      },
      {
        yAxisLabel: "Relative Humidity / %",
        dataExtractor: (item) => item.relative_humidity,
        crossHairValue: readingCrossHairValues.humidity,
        crossHairValueAttr: "humidity",
      },
    ];

    const fmt = this.locale["format"];
    const getFormattedX = (v) => {
      if (v && v.x) {
        return format(v.x, fmt);
      }
      return "";
    }

    const renderedPlots = parameterData.map((pd) => {
      function makeState(v) {
        var ret = {
          ...readingCrossHairValues,
        };
        ret[pd.crossHairValueAttr] = v;
        return ret;
      }

      const paramReadings = this.extractReadings(readings, pd.dataExtractor);

      return (
        <Row>
          <Col className="">
            <FlexibleXYPlot
              stroke="#007bff"
              xType="time"
              height={400}
              onMouseLeave={() => this.setState({ readingCrossHairValues: makeState(false) })}
            >
              <Crosshair
                values={pd.crossHairValue ? [pd.crossHairValue] : []}
                style={{ line: { background: "#007bff" } }}
              >
                <div className="cross-hair">
                  <p>{getFormattedX(pd.crossHairValue)}</p>
                  <p>{pd.crossHairValue.y}</p>
                </div>
              </Crosshair>
              <XAxis />
              <YAxis title={pd.yAxisLabel} />
              <LineSeries
                data={paramReadings}
                onNearestX={(d) => this.setState({
                  readingCrossHairValues: makeState(d)
                })}
              />
            </FlexibleXYPlot>
          </Col>
        </Row>
      );
    });

    return renderedPlots;
  }

  extractUrlParams() {
    const tabKey = function () {
      const path = window.location.pathname;
      if (path === READINGS_PATH) {
        return "readings";
      } else if (path === DAILY_SUMMARIES_PATH) {
        return "daily_summaries";
      } else {
        return "readings";
      }
    }();
    const query = parseUrl(window.location.href);

    var { fromTimestamp, toTimestamp, fromDate, toDate } = this.state;

    if (query.query.from_timestamp) {
      const timestamp = new Date(decodeURI(query.query.from_timestamp));
      if (isValidDate(timestamp)) {
        fromTimestamp = timestamp;
      }
    }
    if (query.query.to_timestamp) {
      const timestamp = new Date(decodeURI(query.query.to_timestamp));
      if (isValidDate(timestamp)) {
        toTimestamp = timestamp;
      }
    }
    if (query.query.from_date) {
      const date = parse(query.query.from_date, "yyyy-MM-dd", new Date());
      if (isValidDate(date)) {
        fromDate = date;
      }
    }
    if (query.query.to_date) {
      const date = parse(query.query.to_date, "yyyy-MM-dd", new Date());
      if (isValidDate(date)) {
        toDate = date;
      }
    }

    return {
      tabKey,
      fromTimestamp,
      toTimestamp,
      fromDate,
      toDate,
    }
  }

  render() {
    const {
      errors,
      latestData,
    } = this.state;

    const fmt = this.locale["format"];
    const formattedLatestTimestmap =
      latestData.timestamp ?
        format(latestData.timestamp, fmt) :
        "-- --- ---- @ --:--:--";

    const formattedLatestData =
      `${latestData.pressure || "----.--"} hPa / ` +
      `${latestData.temperature || "--.-"} °C / ` +
      `${latestData.humidity || "--.-"}% on ` +
      formattedLatestTimestmap;

    const errorElems = errors.map((e, i) => {
      return <Row>
        <Col className="text-center">
          <Alert
            className="mb-1"
            variant="danger"
            onClose={() => this.removeError(i)}
            dismissible
          >{e}</Alert>
        </Col>
      </Row>;
    });

    const {
      tabKey,
      fromTimestamp,
      toTimestamp,
      fromDate,
      toDate,
    } = this.extractUrlParams();

    const { readingsUrl, dailySummariesUrl } = buildUrls(
      fromTimestamp, toTimestamp, fromDate, toDate,
    );

    return (
      <>
        <Nav className="bg-primary text-light mb-4 pt-2 pb-2">
          <Container fluid="md">
            <Row className="align-readings-center">
              <Col>
                <h2 className="mb-0">Týr</h2>
              </Col>
              <Col className="text-right">
                <h5 className="mb-0">Atmospheric readings for St Albans, UK</h5>
                <h6 className="mb-1 mt-2">Latest: {formattedLatestData}</h6>
              </Col>
            </Row>
          </Container>
        </Nav>
        <Container fluid="md">
          {errorElems}
          <Tabs
            className="mt-3"
            defaultActiveKey={tabKey || "readings"}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <Tab eventKey="readings" title="Readings" transition={false}>
              <Row>
                <Col />
                <Col className="text-center pt-3" xs={8}>
                  <h5>Graph time range</h5>
                </Col>
                <Col className="pt-3 text-right">
                  <p><a href={readingsUrl}>[permalink]</a></p>
                </Col>
              </Row>
              <Row>
                <Col />
                <Col xs={7}>
                  <TimeSpanPicker
                    fromTimestamp={fromTimestamp}
                    toTimestamp={toTimestamp}
                    format={fmt}
                    onApply={(from, to) => this.setTimestamps(from, to)}
                  />
                </Col>
                <Col className="text-right">
                  <Button onClick={() => this.downloadReadings()}>Download CSV</Button>
                </Col>
              </Row>
              {this.renderReadings()}
            </Tab>
            <Tab eventKey="daily_summaries" title="Daily Summaries" transition={false}>
              <Row>
                <Col />
                <Col className="text-center pt-3" xs={8}>
                  <h5>Graph time range</h5>
                </Col>
                <Col className="pt-3 text-right">
                  <p><a href={dailySummariesUrl}>[permalink]</a></p>
                </Col>
              </Row>
              <Row>
                <Col />
                <Col xs={7}>
                  <DateSpanPicker
                    fromDate={fromDate}
                    toDate={toDate}
                    format={"d MMM yyyy"}
                    onApply={(from, to) => this.setDates(from, to)}
                  />
                </Col>
                <Col className="text-right">
                  <Button onClick={() => this.downloadDailySummaries()}>Download CSV</Button>
                </Col>
              </Row>
              {this.renderSummaries()}
            </Tab>
          </Tabs>
        </Container>
      </>
    );
  }
}

function isValidDate(d) {
  if (isNaN(d.getTime())) {
    return false;
  }
  return true;
}

function buildUrls(fromTimestamp, toTimestamp, fromDate, toDate) {
  const fromTimestampParam = encodeURIComponent(fromTimestamp.toISOString());
  const toTimestampParam = encodeURIComponent(toTimestamp.toISOString());
  const fromDateParam = encodeURIComponent(format(fromDate, "yyyy-MM-dd"));
  const toDateParam = encodeURIComponent(format(toDate, "yyyy-MM-dd"));

  return {
    readingsUrl:
      `${READINGS_PATH}?from_timestamp=${fromTimestampParam}&to_timestamp=${toTimestampParam}`,
    dailySummariesUrl:
      `${DAILY_SUMMARIES_PATH}?from_date=${fromDateParam}&to_date=${toDateParam}`,
  }
}

export default App;
