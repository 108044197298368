import React, { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-day-picker/lib/style.css';

import {
  differenceInDays,
  format,
  sub
} from "date-fns";
import DayPicker from 'react-day-picker';
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';


class DateSpanPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: props.fromDate,
      toDate: props.toDate,
    };
    this.locale = {
      "format": props.format,
    };
    this.onApply = props.onApply;
  }

  componentDidMount() {
    const { fromDate, toDate } = this.state;
    this.onApply(fromDate, toDate);
  }

  setDates(fromDate, toDate) {
    if (fromDate === this.state.fromDate &&
      toDate === this.state.toDate) {
      return;
    }
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
    });
    this.onApply(fromDate, toDate);
  }

  setFromDate(fromDate) {
    const { toDate } = this.state;
    this.setDates(fromDate, toDate)
  }

  setToDate(toDate) {
    const { fromDate } = this.state;
    this.setDates(fromDate, toDate)
  }

  render() {
    const { fromDate, toDate } = this.state;
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col className="p-0" md="auto">
            <DatePicker
              date={fromDate}
              format={this.locale["format"]}
              onApply={(d) => this.setFromDate(d)}
            />
          </Col>
          <Col className="p-0" md="auto">
            <DatePicker
              date={toDate}
              format={this.locale["format"]}
              onApply={(d) => this.setToDate(d)}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

class DatePicker extends Component {
  constructor(props) {
    super(props);
    const days = differenceInDays(new Date(), props.date);
    this.state = {
      days: days,
      date: props.date,
    };
    this.onApplyProp = props.onApply;
  }

  onApply(date, extra) {
    const days = differenceInDays(new Date(), date);
    this.setState({
      date: date,
      days: extra.days || days,
    });
    this.onApplyProp(date);
  }

  renderPicker() {
    const { days, date } = this.state;
    return (
      <Popover className="mw-100 w-25rem" transition={false}>
        <Popover.Content className="w-25rem">
          <Tabs defaultActiveKey="relative">
            <Tab eventKey="relative" title="Relative">
              <RelativePicker
                days={days}
                date={date}
                onChange={(s) => this.setState(s)}
                onApply={(d, extra) => this.onApply(d, extra)}
              />
            </Tab>
            <Tab eventKey="absolute" title="Absolute">
              <AbsolutePicker
                date={date}
                onChange={(s) => this.setState(s)}
                onApply={(d) => this.onApply(d, {})}
              />
            </Tab>
          </Tabs>
        </Popover.Content>
      </Popover>
    );
  }

  render() {
    const { date } = this.props;
    return (
      <>
        <OverlayTrigger
          rootClose={true}
          trigger="click"
          placement="bottom"
          overlay={this.renderPicker()}
        >
          <Form.Control
            className="text-center mw-20rem"
            type="text"
            readonly
            value={format(date, this.props.format)}
          />
        </OverlayTrigger>
      </>
    )
  }
}

class RelativePicker extends Component {
  constructor(props) {
    super(props);
    this.onChange = props.onChange;
    this.onApply = props.onApply;
  }

  apply() {
    const { days } = this.props;
    const date = sub(new Date(), { days: days });
    const extra = {
      days: days,
    };
    this.onApply(date, extra);
  }

  render() {
    const { days } = this.props;
    return (
      <Form>
        <Form.Row className="mt-3">
          <Form.Group as={Col}>
            <Form.Control
              custom
              id="days-value"
              className="w-100 h-100 text-center"
              type="number"
              onChange={(e) => this.onChange({
                days: e.target.value,
              })}
              value={days}
            />
          </Form.Group>
          <Form.Label
            className="w-25"
            style={{
              fontSize: "12pt",
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >Days ago</Form.Label>
        </Form.Row>
        <Form.Row>
          <Form.Group className="text-center" as={Col}>
            <Button onClick={() => this.apply()}>Apply</Button>
          </Form.Group>
        </Form.Row>
      </Form>
    )
  }
}

class AbsolutePicker extends Component {
  constructor(props) {
    super(props);
    this.onApply = this.props.onApply;
    this.onChange = this.props.onChange;
  }

  handleDayClick(day) {
    this.props.onChange({
      date: day,
    });
  }

  apply() {
    this.onApply(this.props.date);
  }

  render() {
    const { date } = this.props;
    const selection = [date, date];
    return (
      <>
        <Container>
          <Row>
            <Col className="p-0">
              <DayPicker
                className="Selectable"
                numberOfMonths={1}
                selectedDays={selection}
                onDayClick={(d) => this.handleDayClick(d)}
              />
            </Col>
            <Col>
              <Container className="p-0">
                <Row>
                  <Col className="text-center pl-0 pr-0 pt-3">
                    <Button onClick={() => this.apply()}>Apply</Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default DateSpanPicker;