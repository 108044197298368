import React from 'react';
import { AbstractSeries } from 'react-vis';

class VerticalBarWithErrorsSeries extends AbstractSeries {
  static getParentConfig(attr) {
    return {
      isDomainAdjustmentNeeded: attr === 'x',
      zeroBaseValue: attr === 'y',
    };
  }

  render() {
    const { data, marginLeft, marginTop, style, barWidth = 0.85 } = this.props;

    if (!data) {
      return null;
    }

    const distance = this._getScaleDistance('x');
    const lineFunctor = this._getAttributeFunctor('x');
    const valueFunctor = this._getAttributeFunctor('y');
    const value0Functor = this._getAttr0Functor('y');
    const fillFunctor =
      this._getAttributeFunctor('fill') || this._getAttributeFunctor('color');
    const strokeFunctor =
      this._getAttributeFunctor('stroke') || this._getAttributeFunctor('color');
    const opacityFunctor = this._getAttributeFunctor('opacity');

    return (
      <g transform={`translate(${marginLeft},${marginTop})`}>
        {data.map((d, i) => {
          const totalSpace = lineFunctor(d);
          const spacePerBar = distance * barWidth;
          const middleOfBar = totalSpace - spacePerBar / 2;

          const minValue0 = value0Functor({...d, y: d.yMin});
          const minValue = valueFunctor({...d, y: d.yMin});
          const meanValue0 = value0Functor({...d, y: d.yMean});
          const meanValue = valueFunctor({...d, y: d.yMean});
          const maxValue0 = value0Functor({...d, y: d.yMax});
          const maxValue = valueFunctor({...d, y: d.yMax});

          const rectStyleAttrs = {
            opacity: opacityFunctor && opacityFunctor(d),
            fill: fillFunctor && fillFunctor(d),
            ...style,
          };
          const lineStyleAttrs = {
            opacity: opacityFunctor && opacityFunctor(d),
            stroke: strokeFunctor && strokeFunctor(d),
            strokeWidth: 2,
          }

          const rectAttrs = {
            style: rectStyleAttrs,
            x: middleOfBar,
            width: spacePerBar,
            y: Math.min(meanValue0, meanValue),
            height: Math.abs(-meanValue0 + meanValue),
            key: `yMean${i}`,
          };
          const errorLineAttrs = {
            style: lineStyleAttrs,
            x1: middleOfBar + spacePerBar / 2,
            x2: middleOfBar + spacePerBar / 2,
            y1: Math.min(minValue0, minValue),
            y2: Math.min(maxValue0, maxValue),
            key: `yRange${i}`,
          }
          return <>
            <rect {...rectAttrs} />
            <line {...errorLineAttrs} />
          </>;
        })}
      </g>
    );
  }
}

export default VerticalBarWithErrorsSeries;
